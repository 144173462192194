import { TEST_IDS } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { LightIcon, NavAddCircle } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { Button, ParagraphWithTooltip } from '@va/ui/design-system';
import { CloseButton } from '@va/ui/tooltips';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NextResetDate } from './NextResetDate';

const PlanNameWithUpgradeBtn: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const translate = useTranslate();
  const hasUpgradePermission = useSelector(hasPermission);
  const dispatch = useDispatch();
  const featurePermissions: any = useSelector((state: {}) => getApiRequest(state, 'getFeaturePermissions'));
  const unlimitedVoucher = featurePermissions.unlimitedVoucher;
  const planName = useSelector(getCurrentPlanName);

  const currentPlanName = useMemo(() => {
    return unlimitedVoucher
      ? translate('plan.voucherWithCode', {
          voucherCode: unlimitedVoucher.voucherCode,
        })
      : planName;
  }, [planName, translate, unlimitedVoucher]);

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex gap-4 justify-between items-center'>
        <div className='flex gap-3 items-center truncate'>
          <div className='flex items-center justify-center h-12 w-12 rounded-12 bg-black-dark/75 shrink-0'>
            <LightIcon color='#FFFFFF' />
          </div>
          <div className='flex flex-col'>
            <ParagraphWithTooltip className='truncate cursor-default font-525'>
              {`${translate('featureCounter.currentPlan')} ${currentPlanName}`}
            </ParagraphWithTooltip>
            <NextResetDate />
          </div>
        </div>
        <CloseButton onClose={onClose} iconColor='#FFFFFF' className='m-2' />
      </div>

      <Button
        icon={(_, color) => <NavAddCircle color={color} />}
        text={translate('button.upgradePlan')}
        data-testid={TEST_IDS.generic.buttons.upgrade}
        className='!bg-white !hover:bg-white-light justify-center font-525'
        color='tertiary'
        tooltip={!hasUpgradePermission && translate('tooltips.contributorRole')}
        onClick={() => {
          if (!hasUpgradePermission) return;
          dispatch(startUpgradeProcess());
        }}
      />
    </div>
  );
};

export default PlanNameWithUpgradeBtn;
